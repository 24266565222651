body
    background: #eee
    font-family: sans-serif

*
    font-family: 'sans-serif'
    user-select: none
    pointer: default

a
    text-decoration none

p
    margin 0

.Center
    display flex
    flex-flow column nowrap
    align-items center
    justify-content: center

    position fixed
    top: 0
    right: 0
    bottom: 0
    left: 0

.Form, .Form form
    display flex
    flex-flow column nowrap

    p
        font-size 22px

    input[type=password] {
        font-size 22px
    }
    
    > *:not(:first-child)
        margin-top 12px
.KeyPin
    border 2px solid #929292
    border-radius 5px
    margin 12px
    padding: 8px
    box-sizing: border-box
    box-shadow: 2px 2px 5px #00000040
    background: #fff
    position relative
    height: 52px

    &&--position
        border-color #18c52d
        box-shadow: 2px 2px 5px #18c52d40

    &&--unlocked
        border-color #18c52d
        box-shadow: 2px 2px 5px #18c52d40

        .KeyPin__knob
            background #35b335
    
    &__position-marker-wrapper
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        z-index: 1

    &__position-marker
        background: #9fdaa6
        height: 100%

    &__knob
        box-shadow: 2px 2px 5px #00000040
        border-radius: 100%
        position: absolute
        top: 0
        bottom: 0
        width: 48px
        background-color: #727272
        z-index 2
